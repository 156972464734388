const OrderTracking = {
    checkYourOrder: "uiText.orderTracking.checkYourOrder",
    orderNumber: "orderConfirmation.orderNumber",
    orderEmail: "uiText.orderTracking.orderEmail",
    checkStatus: "uiText.orderTracking.checkStatus",
    orderStatus: "uiText.contactForm.myQuestionOptions.orderStatus",
    itemStatus: "uiText.orderTracking.itemStatus",
    trackingNumber: "uiText.orderTracking.trackingNumber",
    orderDate: "orderConfirmation.orderDate",
    orderType: "uiText.orderTracking.orderType",
    trackOrder: "uiText.orderTracking.trackOrder",
    viewReturnDetails: "uiText.orderTracking.viewReturnDetails",
    backToAllOrders: "uiText.orderTracking.backToAllOrders",
    backToOrder: "uiText.orderTracking.backToOrder",
    loadMoreItems: "uiText.orderTracking.loadMoreItems",
    amountToBeReturned: "uiText.orderTracking.amountToBeReturned",
    refundDetails: "uiText.orderTracking.refundDetails",
    promotions: "uiText.orderTracking.promotions",
    orderDetails: "uiText.orderTracking.orderDetails",
    viewOriginalOrder: "uiText.orderTracking.viewOriginalOrder",
    orderTypeLabel: {
        pos: "uiText.orderTracking.orderTypeLabel.pos",
        ecom: "uiText.orderTracking.orderTypeLabel.ecom",
        sendsale: "uiText.orderTracking.orderTypeLabel.sendsale",
        dropship: "uiText.orderTracking.orderTypeLabel.dropship",
        orderInStore: "uiText.orderTracking.orderTypeLabel.orderInStore",
        clickAndCollect: "uiText.orderTracking.orderTypeLabel.clickAndCollect",
        clickAndReserve: "uiText.orderTracking.orderTypeLabel.clickAndReserve",
        reserveInStore: "uiText.orderTracking.orderTypeLabel.reserveInStore",
    },
    orderStatusLabel: {
        open: "uiText.orderTracking.orderStatusLabel.open",
        complete: "uiText.orderTracking.orderStatusLabel.complete",
        cancelled: "uiText.orderTracking.orderStatusLabel.cancelled",
    },
    subtotal: "uiText.subtotal",
    discountsAndSavings: "uiText.discount",
    shipping: "uiText.shipping",
    total: "uiText.total",
    orderTotal: "uiText.orderTracking.orderTotal",
    salesTax: "uiText.orderTracking.salesTax.title",
    loadMoreOrders: "uiText.orderTracking.loadMoreOrders",
    shippingAddress: "checkout.step2.shippingAddress.title",
    billingAddress: "checkout.step2.billingAddress.title",
    productDetailsUnavailable: "uiText.orderTracking.productDetailsUnavailable",
    pickupAddress: "uiText.orderTracking.pickupYourAddressAt",
    pickupDate: "uiText.orderTracking.pickupDate.title",
    giftWrapping: "uiText.giftWrapping.label",
};

export default OrderTracking;
