import { object, string } from "yup";
import { emailRegex } from "../regex";

export interface LogInHeaders {
    requiredField: string;
    email: string;
    maxCharacters?: string;
}

export function getEmailValidationSchema(headers: LogInHeaders) {
    return object().shape({
        email: string()
            .required(headers.requiredField)
            .email(headers.email)
            .max(35, headers.maxCharacters)
            .concat(checkValidEmail(headers.email)),
    });
}

export function getLogInValidationSchema(headers: LogInHeaders) {
    return object().shape({
        email: string()
            .trim()
            .required(headers.requiredField)
            .matches(emailRegex, headers.email)
            .email(headers.email)
            .max(35, headers.maxCharacters)
            .concat(checkValidEmail(headers.email)),
        password: string().required(headers.requiredField),
    });
}

export const checkValidEmail = (message: string) => {
    return string().test({
        name: "valid-email-address",
        exclusive: true,
        message: message,
        test: (value) => emailRegex.test(value),
    });
};
