// The scale was built using https://coolors.co/
// the colors available in Figma was used - and the algorithm in the tool helped fill out the rest.
const computedEccoGray = {
    50: "#EEEEEE",
    100: "#EEEEEE",
    150: "#D2D2D2",
    200: "#D2D2D2",
    300: "#D2D2D2",
    400: "#D2D2D2",
    500: "#767676",
    600: "#767676",
    700: "#767676",
    800: "#464646",
    900: "#464646",
};

const colors = {
    transparent: "transparent",
    current: "currentColor",
    black: "#000000",
    white: "#FFFFFF",
    gray: computedEccoGray,
    red: {
        50: "#FCF2F4",
        100: "#A42B1E",
        200: "#A42B1E",
        300: "#A42B1E",
        400: "#A42B1E",
        500: "#A42B1E",
        600: "#A42B1E",
        700: "#A42B1E",
        800: "#A42B1E",
        900: "#A42B1E",
    },
    yellow: {
        50: "#F3EEDD",
        100: "#EDC021",
        200: "#EDC021",
        300: "#EDC021",
        400: "#EDC021",
        500: "#EDC021",
        600: "#EDC021",
        700: "#EDC021",
        800: "#EDC021",
        900: "#EDC021",
    },
    green: {
        50: "#BAF9B8",
        100: "#327533",
        200: "#327533",
        300: "#327533",
        400: "#327533",
        500: "#327533",
        600: "#327533",
        700: "#327533",
        800: "#327533",
        900: "#327533",
    },
    blue: {
        50: "#ECF3FD",
        200: "#042CA0",
    },
    whiteTransparent: `rgba(255, 255, 255, 0.92)`,
    grayHover: "#E8E8E8",
    errorOnBlack: "#FF513D",
    missingLinkRed: "#DA4432",
};

export default colors;
