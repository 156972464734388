import { useGlobalModalContext } from "@components/GlobalModalProvider";
import type { ILink, IPagePath } from "@contentful-api/types/contentful";
import type { TextProps } from "@ui/components/content/text/Text";
import { Text } from "@ui/components/content/text/Text";
import { defaultUnderlineStyle } from "@ui/css/common";
import { isEmpty } from "lodash-es";
import { forwardRef } from "react";

interface IModalLinkProps extends TextProps {
    link: ILink | IPagePath;
}

export const ModalLink = forwardRef<HTMLParagraphElement, IModalLinkProps>(
    ({ link, children, ...rest }, ref) => {
        const _link = link as ILink;
        const label = _link?.fields?.label || "";
        const description = _link?.fields?.description || "";

        const { showModal } = useGlobalModalContext();

        const onClick = () => {
            showModal(_link?.fields?.modal, { description, label });
        };

        return (
            <Text
                data-type="modal-link"
                as="button"
                color="inherit"
                fontSize="inherit"
                cursor="pointer"
                onClick={onClick}
                data-testid={`${_link?.fields?.modal}-link`}
                {...rest}
                ref={ref}
                sx={{
                    // We need to spread the sx prop so it's not overwritten by the default styles
                    ...rest?.sx,
                    // We are not using Link here, so we need to overwrite the default styles of the Button with our link underline type styles
                    transitionProperty: "common",
                    transitionDuration: "fast",
                    transitionTimingFunction: "ease-out",
                    ...defaultUnderlineStyle,
                    fontWeight: "bold",
                    textDecoration: "underline",
                    _hover: {
                        color: "gray.500",
                        textDecorationColor: "gray.500",
                    },
                    // We need this because the way of how Contentful hanldes rich text.
                    // If editors use a mark, then it will be wrapped in a span tag, so in this way we force no matter what editors use, we will have consistency
                    "& span": {
                        fontWeight: "bold",
                        textDecoration: "underline",
                    },
                    _focusVisible: {
                        outline: "2px solid",
                        outlineColor: "gray.500",
                        outlineOffset: "2px",
                        textDecoration: "none",
                    },
                }}
            >
                {isEmpty(children) ? label : children}
            </Text>
        );
    }
);

ModalLink.displayName = "ModalLink";
