import { FetchServiceAxios } from "@lib/helpers/fetchServiceAxios";

declare const grecaptcha: any;

export const validateRecaptcha = async ({
    onSuccess,
    onAbort,
    onError,
    action,
    shouldCheck = true,
}: {
    onSuccess?: () => void | Promise<void>;
    onAbort?: () => void | Promise<void>;
    onError?: (e: Error) => void | Promise<void>;
    action: string;
    shouldCheck?: boolean;
}) => {
    if (shouldCheck) {
        await grecaptcha.enterprise.ready(async () => {
            const token = await grecaptcha.enterprise.execute(
                process.env.NEXT_PUBLIC_RECAPTCHA_TOKEN,
                {
                    action,
                }
            );

            try {
                const recaptchaResponse: { riskAnalysis: { score: number } } =
                    await FetchServiceAxios.post(`/api/validate-recaptcha`, {
                        recaptchaToken: token,
                    });

                const recaptchaCheckPassed = recaptchaResponse?.riskAnalysis.score > 0.7;

                if (recaptchaCheckPassed) {
                    onSuccess && (await onSuccess());
                } else {
                    onAbort && (await onAbort());
                }
            } catch (e) {
                onError && (await onError(e));
            }
        });
    } else {
        try {
            onSuccess && (await onSuccess());
        } catch (e) {
            onError && (await onError(e));
        }
    }
};
