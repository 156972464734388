export enum ModalTypes {
    login = "login",
    contact = "contact",
    signOut = "signOut",
    sizeGuide = "sizeGuide",
    createAccount = "createAccount",
    forgotPassword = "forgotPassword",
    thankYouForSignUp = "thankYouForSignUp",
    contactForm = "contactForm",
    contactFormSubmitted = "contactFormSubmitted",
    shippingDetails = "shippingDetails",
    liveChat = "liveChat",
    chatIsNotAvailable = "chatIsNotAvailable",
    chat = "chat",
    giftWrapping = "giftWrapping",
    base = "base",
}
